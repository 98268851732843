import React, { useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import NavLinkTwo from "../navLink/NavLinkTwo";
// import SimpleBtn from "../button/Btn";

var lastScrollTop = 0;
// var heroHeight = 0;

export default function Mobile({ data: { logo, menu, subhamburger } }) {
  const [mega, setMega] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [direction, setDirection] = useState(0);
  const [activeIndex, setActiveIndex] = useState(1000);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // heroHeight = document.getElementById("hero")?.offsetHeight;
      window.addEventListener("scroll", handleScroll, false);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > lastScrollTop) {
      // downscroll code
      setDirection("down");
    } else if (scrollTop < lastScrollTop) {
      // upscroll code
      setDirection("top");
    }
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    setScroll(window.scrollY);
  };
  useEffect(() => {
    if (mega) {
      document.body.style.overflow = "hidden";
      document.getElementById("mega").style.visibility = "visible";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [mega]);

  const toggleMega = () => {
    setMega(!mega);
  };

  const toggleSubMenu = (index, item) => {
    if (index === activeIndex) {
      setActiveIndex(1000);
    } else {
      setActiveIndex(index);
    }

    if (item?.link?.type !== "label") {
      toggleMega();
    }
  };

  return (
    <header>
      <div
        className={`fixed top-0 z-10 w-full transition-transform  ${
          scroll < 80
            ? ""
            : direction === "top"
            ? "slideIn shadow-md"
            : "slideOut"
        } ${scroll > 100 ? "bg-white" : "bg-transparent shadow-none"}`}
      >
        <nav className="px-4 md:px-8 xl:px-14 2xl:px-[82px] py-7 w-full transition delay-[0.3s] ease-linear">
          <div className="flex items-center justify-between">
            <Link to="/">
              <div className="max-w-[150px] md:max-w-[280px] lg:max-w-[325px]">
                <GatsbyImage
                  image={logo?.asset?.gatsbyImageData}
                  alt="Solectrac"
                />
              </div>
            </Link>
            <div className="flex items-center">
              {/* Burger Icon */}
              <button className="md:ml-7 2xl:ml-[60px] w-[52px] h-[52px] flex justify-center items-center z-[100]">
                <div className={`burger`} onClick={toggleMega}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    viewBox="0 0 200 200"
                  >
                    <g strokeWidth="6.5" strokeLinecap="round">
                      <path
                        d="M72 82.286h28.75"
                        fill="#009100"
                        fillRule="evenodd"
                        stroke="#000"
                      />
                      <path
                        d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
                        fill="none"
                        stroke="#000"
                      />
                      <path
                        d="M72 125.143h28.75"
                        fill="#009100"
                        fillRule="evenodd"
                        stroke="#000"
                      />
                      <path
                        d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
                        fill="none"
                        stroke="#000"
                      />
                      <path
                        d="M100.75 82.286h28.75"
                        fill="#009100"
                        fillRule="evenodd"
                        stroke="#000"
                      />
                      <path
                        d="M100.75 125.143h28.75"
                        fill="#009100"
                        fillRule="evenodd"
                        stroke="#000"
                      />
                    </g>
                  </svg>
                </div>
              </button>
            </div>
          </div>
          {/* Menu */}
          <div
            className={`fixed top-0 left-0 right-0 bottom-0 px-5 sm:p-10 bg-[#2D4774] z-[999] transition-transform ease-in-out duration-1000 ${
              mega ? "translate-x-0" : "translate-x-[2000px]"
            } `}
            id="mega"
          >
            <span
              className="fixed z-50 right-3 sm:right-10 top-4 sm:top-8"
              onClick={toggleMega}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="white"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </span>
            <div className="flex flex-col items-center justify-center h-full overflow-y-auto  pt-[150px]">
              <ul className=" text-slate-200 text-xl leading-[50px] w-[90%] sm:w-4/6">
                {menu?.length > 0 &&
                  menu.map((item, index) => (
                    <NavLinkTwo
                      key={item?.link?.label}
                      linkType={item?.link?.type}
                      label={item?.link?.label}
                      url={item?.link?.url}
                      path={item?.link?.path}
                      childMenu={item?.childMenu}
                      arrow={false}
                      cls={`relative overflow-hidden transisition-all duration-1000 ease-linear ${
                        activeIndex === index ? "max-h-[400px]" : "max-h-[40px]"
                      }`}
                      onClick={() => toggleSubMenu(index, item)}
                    >
                      {item.childMenu.length > 0 &&
                        (activeIndex === index ? (
                          <span className="absolute right-2 top-[10px]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span className="absolute right-2 top-[10px]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="white"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                              />
                            </svg>
                          </span>
                        ))}
                      {item?.childMenu?.length > 0 && (
                        <ul className="p-2 text-base shadow-md w-[220px]">
                          {item?.childMenu?.map((child) => (
                            <NavLinkTwo
                              key={child?.label}
                              link={child?.link}
                              label={child?.label}
                              url={child?.url}
                              path={child?.path}
                              arrow={false}
                              onClick={toggleMega}
                            />
                          ))}
                        </ul>
                      )}
                    </NavLinkTwo>
                  ))}
              </ul>
              <div className="border-t-[0.5px] w-[90%] sm:w-4/6 border-slate-400 mt-10 text-lg list-none pt-5">
                {subhamburger?.map((child, i) => (
                  <NavLinkTwo
                    key={i}
                    link={child?.link}
                    label={child?.label}
                    url={child?.url}
                    path={child?.path}
                    onClick={toggleMega}
                    cls="text-white font-normal mb-1"
                  />
                ))}
                {/* {button && button?.linkType && (
                  <SimpleBtn
                    label={button.title}
                    link={button?.link}
                    linkType={button.linkType}
                    onClick={toggleMega}
                    cls="text-slate-200 text-2xl relative top-7 hover:text-white"
                  />
                )} */}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
