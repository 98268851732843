import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";
import useWindowSize from "../../../hooks/useWindowSize";

export default function Header({ data }) {
  const { width } = useWindowSize();

  if (width === 0) return <div />;

  return width > 768 ? <Desktop data={data} /> : <Mobile data={data} />;
}
