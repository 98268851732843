import React from "react";
import { Link } from "gatsby";

export default function OutlineBtn({
  linkType,
  label,
  onClick = null,
  link,
  cls = "",
  white = false,
}) {
  if (linkType === "internal") {
    return (
      <Link
        to={link}
        className={`${
          white ? "btn-secondary" : "btn-primary bg-white bg-opacity-[0.42]"
        }  ${cls}`}
      >
        {label}
      </Link>
    );
  } else if (linkType === "external") {
    return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer noopener"
        className={`${
          white ? "btn-secondary" : "btn-primary  bg-white bg-opacity-[0.42]"
        }  ${cls}`}
      >
        {label}
      </a>
    );
  } else {
    return (
      <a
        className={`${
          white ? "btn-secondary" : "btn-primary  bg-white bg-opacity-[0.42]"
        }  ${cls} cursor-pointer`}
        onClick={onClick}
        role="none"
        onKeyDown={onClick}
      >
        {label}
      </a>
    );
  }
}
