import "./src/styles/global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import { CartProvider } from "./src/context/cart";
import Layout from "./src/components/common/layout";

export const wrapPageElement = ({ element, props }) => (
  <CartProvider>
    <Layout {...props}>{element}</Layout>
  </CartProvider>
);
