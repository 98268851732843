import React, { Fragment } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Toaster } from "react-hot-toast";
import Header from "../header";
import Footer from "../footer";

// const OPTIONS = {
//   threshold: 0,
//   rootMargin: "0px 0px -250px 0px",
// };

export default function Layout({ children }) {
  const { sanitySiteSettings } = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        header {
          logo {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
            }
          }
          bg {
            asset {
              url
            }
          }
          navMenu {
            link {
              label
              url
              path
              type
            }
            childMenu {
              path
              type
              url
              label
            }
          }
          menu {
            link {
              label
              url
              path
              type
            }
            childMenu {
              path
              type
              url
              label
              img {
                asset {
                  url
                }
              }
            }
          }
          subhamburger {
            label
            url
            path
            type
          }
          button {
            link
            linkType
            title
          }
        }
        footer {
          logo {
            alt
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          img {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
            }
          }
          address
          _rawCc
          phone
          plabel
          call
          slogan
          menu {
            label
            childMenu {
              label
              path
              type
              url
            }
          }
          social {
            icon
            name
            url
          }
        }
      }
    }
  `);

  // Intersection observer
  // useEffect(() => {
  //   const sliders = document.querySelectorAll(".fadeIn");

  //   const appearOnScroll = new IntersectionObserver(function (
  //     entries,
  //     appearOnScroll
  //   ) {
  //     entries.forEach((entry) => {
  //       if (!entry.isIntersecting) {
  //         return;
  //       } else {
  //         entry.target.classList.add("show");
  //         appearOnScroll.unobserve(entry.target);
  //       }
  //     });
  //   },
  //   OPTIONS);
  //   sliders.forEach((slider) => {
  //     appearOnScroll.observe(slider);
  //   });
  // }, []);

  return (
    <Fragment>
      <Header data={sanitySiteSettings?.header} />
      <main>{children}</main>
      <Footer data={sanitySiteSettings?.footer} />
      <Toaster />
    </Fragment>
  );
}
