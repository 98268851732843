import React from "react";
import { Link } from "gatsby";

export default function SimpleLink({
  linkType,
  path = "",
  url = "",
  label = "",
  cls = "",
  children = null,
}) {
  if (linkType === "label") {
    return (
      <span className={`${cls}`}>
        {label} <>{children}</>
      </span>
    );
  } else if (linkType === "external") {
    return (
      <a
        href={url}
        className={`${cls}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {label}
        <>{children}</>
      </a>
    );
  } else {
    return (
      <Link to={path} className={`${cls}`}>
        {label}
        <>{children}</>
      </Link>
    );
  }
}
