import React from "react";
import { Link } from "gatsby";

// <div className="plus-icon" />
export default function NavLinkTwo({
  linkType,
  path,
  url,
  label,
  children,
  childMenu,
  cls = "",
  onMouseOver = null,
  onMouseOut = null,
  onClick = null,
  arrow = true,
  style = {},
}) {
  return (
    <li
      className={`${cls}`}
      onMouseEnter={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
      style={style}
    >
      {linkType === "label" ? (
        <span className="cursor-pointer">
          {label}
          {childMenu?.length > 0 && arrow && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className=""
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5L16 12L9 19"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </span>
      ) : (
        <>
          {linkType === "external" ? (
            <span>
              <a href={url} target="_blank" rel="noreferrer noopener">
                {label}
              </a>
              {childMenu?.length > 0 && arrow && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5L16 12L9 19"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </span>
          ) : (
            <span>
              <Link to={`${path}`}>{label}</Link>
              {childMenu?.length > 0 && arrow && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5L16 12L9 19"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </span>
          )}
        </>
      )}

      {children}
    </li>
  );
}
