import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import SimpleLink from "../navLink/Simple";
import { PortableText } from "@portabletext/react";

export default function Footer({
  data: {
    logo,
    address,
    img,
    slogan,
    cc,
    menu,
    social,
    phone,
    plabel,
    call,
    _rawCc,
  },
}) {
  return (
    <footer className="bg-[#F9F9F9] bg-[url('../assets/svgs/bg-footer.svg')] bg-no-repeat bg-[left_-20px_bottom]">
      <div className="pt-[90px] px-4 md:px-16 2xl:px-[133px] pb-12">
        <div className="grid grid-cols-12 gap-x-0 gap-y-8 xs:gap-8">
          <div className="col-span-12 md:col-span-6 xl:col-span-4">
            <div>
              <Link to="/">
                <div className="max-w-[280px] 2xl:max-w-[333px]">
                  <GatsbyImage
                    image={logo?.asset?.gatsbyImageData}
                    alt={logo?.alt}
                  />
                </div>
              </Link>
            </div>
            <div className="mt-10 md:mt-[78px] text-base xs:text-lg 2xl:text-2xl 2xl:leading-[29px] font-normal">
              {address && <p className="mb-5">{address}</p>}

              <p>
                {plabel && plabel}{" "}
                {phone && <a href={`tel:${call}`}>{phone}</a>}
              </p>

              <div className="mt-10 2xl:mt-[46px] mb-6">
                <GatsbyImage
                  image={img?.asset?.gatsbyImageData}
                  alt={img?.alt}
                />
              </div>
              {slogan && (
                <p className="text-lg leading-6 2xl:text-xl">
                  <em>{slogan}</em>
                </p>
              )}
            </div>
            <div className="flex items-center mt-10 space-x-7">
              {social &&
                social?.map((item, index) => {
                  return (
                    <a
                      href={item?.url}
                      target="_blank"
                      rel="noreferrer noopener"
                      key={index}
                      className="footer-social"
                    >
                      <span dangerouslySetInnerHTML={{ __html: item?.icon }} />
                    </a>
                  );
                })}
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-8">
            {menu && (
              <div className="grid grid-cols-12 gap-x-2 gap-y-8 xs:gap-8 xl:gap-4">
                {menu.map((item, index) => {
                  return (
                    <div className="col-span-6 xl:col-span-3" key={index}>
                      <h4 className="text-[#4F78BC] text-xl 2xl:text-2xl leading-8 2xl:leading-9 font-semibold">
                        {item.label}
                      </h4>

                      <ul className="flex flex-col mt-10 space-y-4 lg:space-y-6 2xl:space-y-8">
                        {item?.childMenu?.map((child, n) => (
                          <li key={n}>
                            <SimpleLink
                              key={child?.label}
                              linkType={child?.type}
                              label={child?.label}
                              url={child?.url}
                              path={child?.path}
                              cls="text-base 2xl:text-lg leading-5 2xl:leading-[22px] font-medium hover:border-b-2 border-black max-w-max"
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="hidden xl:block mt-16 2xl:mt-[93px] text-lg 2xl:text-xl leading-6 font-semibold">
              {_rawCc && (
                <PortableText
                  value={_rawCc}
                  components={{
                    marks: {
                      color: ({ value, children }) => (
                        <span style={{ color: value.hex }}>{children}</span>
                      ),
                      link: ({ value, children }) => {
                        const target = (value?.href || "").startsWith("http")
                          ? "_blank"
                          : undefined;
                        return (
                          <a
                            href={value?.href}
                            target={target}
                            rel={target === "_blank" && "noindex nofollow"}
                            className="underline transition-colors duration-150 ease-linear underline-offset-2 hover:text-primary"
                          >
                            {children}
                          </a>
                        );
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="block xl:hidden text-center mt-16 2xl:mt-[93px] text-base md:text-xl md:leading-6 font-semibold">
          {_rawCc && (
            <PortableText
              value={_rawCc}
              components={{
                marks: {
                  color: ({ value, children }) => (
                    <span style={{ color: value.hex }}>{children}</span>
                  ),
                  link: ({ value, children }) => {
                    return (
                      <a
                        href={value?.href}
                        className="underline transition-colors duration-150 ease-linear underline-offset-8 hover:text-primary"
                      >
                        {children}
                      </a>
                    );
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </footer>
  );
}
