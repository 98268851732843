import React from "react";
import { Link } from "gatsby";

export default function NavLink({
  linkType,
  path = "",
  url = "",
  label,
  children,
  childMenu,
  cls = "",
}) {
  return (
    <li className={`${cls}`}>
      {linkType === "label" ? (
        <span className="cursor-pointer nav-link">
          {label}
          {childMenu?.length > 0 && <ArrowDown />}
        </span>
      ) : (
        <>
          {linkType === "external" ? (
            <a
              href={url}
              className="nav-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              {label}
              {childMenu?.length > 0 && <ArrowDown />}
            </a>
          ) : (
            <Link to={`${path}`} className="nav-link">
              {label}
              {childMenu?.length > 0 && <ArrowDown />}
            </Link>
          )}
        </>
      )}
      {children}
    </li>
  );
}

const ArrowDown = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline ml-3"
  >
    <path
      d="M9 1L5 5L1 1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
