import React, { useState, useEffect } from "react";

const defaultState = {
  selectedProduct: null,
  reservedProduct: "",
  reservedAttachments: [],
  cartPrice: {
    tractor: 0,
  },
  currentStep: "precheck",
  isCheckoutCompleted: false,
  selectedAttachments: {},
  attachmentsList: [],
  handleReservedProduct: () => {},
  handleReservedAttachments: () => {},
  handleProduct: () => {},
  handleAttachments: () => {},
  setCurrentStep: () => "",
  setIsCheckoutCompleted: () => Boolean,
  clearCart: () => {},
};

const CartContext = React.createContext(defaultState);

function CartProvider({ children }) {
  const [selectedProduct, setSelectedProduct] = useState(
    defaultState.selectedProduct
  );
  const [selectedAttachments, setSelectedAttachments] = useState({});
  const [currentStep, setCurrentStep] = useState(defaultState.currentStep);
  const [isCheckoutCompleted, setIsCheckoutCompleted] = useState(false);
  const [cartPrice, setCartPrice] = useState(defaultState.cartPrice);
  const [reservedProduct, setReservedProduct] = useState("");

  const handleReservedProduct = (product) => {
    setReservedProduct(product);
  };

  const handleProduct = (product, reserved = false) => {
    setSelectedProduct(product);
    if (!reserved) {
      setSelectedAttachments({});
      setCartPrice({
        tractor: product?.price?.price,
      });
    } else {
      setCartPrice({
        ...cartPrice,
        tractor: product?.price?.price,
      });
    }
  };
  const handleReservedAttachments = (items) => {
    setSelectedAttachments(items);
  };

  const handleAttachments = (sectionLabel, item) => {
    const section = selectedProduct?.productAddOns.find(
      (d) => d.label === sectionLabel
    );
    const isMultiSelect = section.isMultiSelect;

    setSelectedAttachments((prevState) => {
      const currentSelection = prevState[sectionLabel] || [];
      const isAlreadySelected = currentSelection.some(
        (i) => i.label === item.label
      );

      if (isAlreadySelected) {
        // If the item is already selected, remove it
        return {
          ...prevState,
          [sectionLabel]: currentSelection.filter(
            (i) => i.label !== item.label
          ),
        };
      }

      if (isMultiSelect) {
        // If multi-select, add the item to the selection
        return {
          ...prevState,
          [sectionLabel]: [...currentSelection, item],
        };
      } else {
        // If single-select, replace with the new item
        return {
          ...prevState,
          [sectionLabel]: [item],
        };
      }
    });
  };

  const calculateSectionPrices = () => {
    const prices = {};
    Object.entries(selectedAttachments).forEach(([sectionLabel, items]) => {
      let totalSectionPrice = 0;
      items.forEach((item) => {
        totalSectionPrice += item.price;
      });
      prices[sectionLabel.toLowerCase()] = totalSectionPrice;
    });
    return prices;
  };

  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    const newSectionPrices = calculateSectionPrices();
    if (!isObjectEmpty(newSectionPrices)) {
      setCartPrice((prevState) => {
        return { ...prevState, ...newSectionPrices };
      }); // Update section prices whenever selectedItems changes
    }
  }, [selectedAttachments]);

  const clearCart = () => {
    if (isCheckoutCompleted) {
      setIsCheckoutCompleted(false);
      setSelectedProduct(defaultState.selectedProduct);
      setCurrentStep(defaultState.currentStep);
      setCartPrice(defaultState.cartPrice);
      setSelectedAttachments({});
    }
  };

  return (
    <CartContext.Provider
      value={{
        selectedProduct,
        currentStep,
        isCheckoutCompleted,
        cartPrice,
        selectedAttachments,
        reservedProduct,
        handleProduct,
        handleReservedProduct,
        handleReservedAttachments,
        handleAttachments,
        setCurrentStep,
        setIsCheckoutCompleted,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default CartContext;

export { CartProvider };
