import React, { useState, useEffect, useCallback } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import NavLink from "../navLink";
import NavLinkTwo from "../navLink/NavLinkTwo";
// import img1 from "../../../assets/images/experience.png";
// import img2 from "../../../assets/images/hero.png";
import OutlineBtn from "../button/Outline";
// import SimpleBtn from "../button/Btn";

var lastScrollTop = 0;
// var heroHeight = 0;

export default function Desktop({
  data: { logo, menu, button, bg, navMenu, subhamburger },
}) {
  const [mega, setMega] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [child, setChild] = useState([]);
  const [direction, setDirection] = useState(0);
  const [menuBg, setBg] = useState(null);
  const [parentIndex, setParentIndex] = useState(1000);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // const hero = document.getElementById("hero");

      // heroHeight = hero ? hero.offsetHeight : 0;
      window.addEventListener("scroll", handleScroll, false);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > lastScrollTop) {
      // downscroll code
      setDirection("down");
    } else if (scrollTop < lastScrollTop) {
      // upscroll code
      setDirection("top");
    }
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    setScroll(window.scrollY);
  };

  const toggleMega = () => {
    setMega(!mega);
    setChild([]);
  };
  useEffect(() => {
    if (mega) {
      document.body.style.overflow = "hidden";
      setBg(bg.asset?.url);
      // document.getElementById("mega").style.visibility = "visible";
    } else {
      document.body.style.overflow = "unset";
      setBg(null);
      // document.getElementById("mega").style.visibility = "hidden";
    }
  }, [mega]);

  const keyPress = useCallback((event) => {
    if (event.key === "Escape") {
      setMega(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyPress, false);

    return () => {
      document.removeEventListener("keydown", keyPress, false);
    };
  }, [keyPress]);

  useEffect(() => {
    if (child.length) {
      const c = document.getElementById("child");
      c.style.opacity = "1";
      c.style.visibility = "visible";
      c.style.transform = "translateX(0)";
    } else {
      const c = document.getElementById("child");
      c.style.opacity = "0";
      c.style.visibility = "hidden";
      c.style.transform = "translateX(-50%)";
    }
  }, [child]);

  const onDivMouseOver = (e) => {
    const childDiv = document.getElementById("child");
    if (!childDiv.contains(e.target)) {
      setChild([]);
      setBg(bg?.asset?.url);
      setParentIndex(1000);
    }
  };

  const onParentOver = (index, val) => {
    setParentIndex(index);
    setChild(val);
    setBg(bg?.asset?.url);
  };

  const onChildMouseOver = (val) => {
    setBg(val);
  };

  return (
    <header>
      <div
        className={`fixed top-0 z-10 w-full transition-transform  ${
          scroll < 100
            ? ""
            : direction === "top"
            ? "slideIn shadow-md"
            : "slideOut"
        } ${scroll > 120 ? "bg-white" : "bg-transparent shadow-none"}`}
      >
        <nav className="px-5 xl:px-8 3xl:px-[82px] py-7 hover:bg-white w-full transition delay-[0.3s] ease-linear">
          <div className="flex items-center justify-between">
            <Link to="/">
              <div className="max-w-[150px] md:max-w-[280px] lg:max-w-[325px]">
                <GatsbyImage
                  image={logo?.asset?.gatsbyImageData}
                  alt="Solectrac"
                />
              </div>
            </Link>
            <div className="flex items-center">
              <ul className="hidden xl:flex  xl:space-x-12  2xl:space-x-16">
                {navMenu?.length > 0 &&
                  navMenu?.map((item) => (
                    <NavLink
                      key={item?.link?.label}
                      linkType={item?.link?.type}
                      label={item?.link?.label}
                      url={item?.link?.url}
                      path={item?.link?.path}
                      childMenu={item?.childMenu}
                      cls="relative group"
                    >
                      {item?.childMenu?.length > 0 && (
                        <ul className="absolute top-7 left-0 bg-white p-5 invisible text-lg shadow-md w-[220px] opacity-0 translate-y-8 transition-all duration-300 group-hover:opacity-100 group-hover:visible group-hover:translate-y-0">
                          {item?.childMenu?.map((child) => (
                            <NavLink
                              key={child?.label}
                              link={child?.link}
                              label={child?.label}
                              url={child?.url}
                              path={child?.path}
                            />
                          ))}
                        </ul>
                      )}
                    </NavLink>
                  ))}
              </ul>
              {button && button?.linkType && (
                <OutlineBtn
                  label={button.title}
                  link={button?.link}
                  linkType={button.linkType}
                  cls="ml-8 2xl:ml-[91px]  hidden lg:block"
                />
              )}
              {/* Burger Icon */}
              <button className="ml-7 2xl:ml-[60px] w-[52px] h-[52px] flex justify-center items-center z-[100] outline-none">
                <div
                  className={`burger ${mega ? "b-active" : ""}`}
                  onClick={toggleMega}
                  role="none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="90"
                    height="90"
                    viewBox="0 0 200 200"
                  >
                    <g strokeWidth="6.5" strokeLinecap="round">
                      <path
                        d="M72 82.286h28.75"
                        fill="#009100"
                        fillRule="evenodd"
                        stroke="#000"
                      />
                      <path
                        d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
                        fill="none"
                        stroke="#000"
                      />
                      <path
                        d="M72 125.143h28.75"
                        fill="#009100"
                        fillRule="evenodd"
                        stroke="#000"
                      />
                      <path
                        d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
                        fill="none"
                        stroke="#000"
                      />
                      <path
                        d="M100.75 82.286h28.75"
                        fill="#009100"
                        fillRule="evenodd"
                        stroke="#000"
                      />
                      <path
                        d="M100.75 125.143h28.75"
                        fill="#009100"
                        fillRule="evenodd"
                        stroke="#000"
                      />
                    </g>
                  </svg>
                </div>
              </button>
            </div>
            {/* Mega Menu */}
            <div
              className={`fixed top-0 right-0 bottom-0 left-0 mega-menu z-50`}
              id="mega"
              style={{ visibility: "hidden" }}
            >
              <div
                className={`left  overflow-y-auto ${
                  mega
                    ? "bottom-0 opacity-100 visible"
                    : " opacity-0 bottom-[2500px] invisible"
                }`}
              >
                <div className="flex flex-col items-center justify-center py-16">
                  <ul className="text-white text-[28px] w-2/3 leading-[34px] parent">
                    {menu?.length > 0 &&
                      menu.map((item, index) => (
                        <NavLinkTwo
                          key={index}
                          linkType={item?.link?.type}
                          label={item?.link?.label}
                          url={item?.link?.url}
                          path={item?.link?.path}
                          childMenu={item?.childMenu}
                          onClick={
                            item?.link?.type === "label" ? null : toggleMega
                          }
                          style={
                            parentIndex === index && item.childMenu.length
                              ? { textDecoration: "underline", left: 15 }
                              : {}
                          }
                          onMouseOver={() =>
                            onParentOver(index, item.childMenu)
                          }
                        />
                      ))}
                  </ul>

                  <div className="border-t w-3/4 border-slate-400 mt-10 px-5 text-xl list-none grid grid-cols-1 lg:grid-cols-2 gap-2 pt-5">
                    {subhamburger?.map((child, i) => (
                      <NavLinkTwo
                        key={i}
                        link={child?.link}
                        label={child?.label}
                        url={child?.url}
                        path={child?.path}
                        onClick={toggleMega}
                        cls="text-white font-primary transistion-colors duration-500 hover:underline underline-offset-2"
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div
                className={`right ${
                  mega
                    ? "bottom-0 opacity-100 visible"
                    : "opacity-0 bottom-[2500px] invisible"
                }`}
                onMouseOver={onDivMouseOver}
                style={{
                  backgroundImage: `url(${menuBg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="child" id="child">
                  <ul>
                    {child?.map((child, i) => (
                      <NavLinkTwo
                        key={i}
                        link={child?.link}
                        label={child?.label}
                        url={child?.url}
                        path={child?.path}
                        onClick={toggleMega}
                        onMouseOver={() =>
                          onChildMouseOver(child?.img?.asset?.url)
                        }
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
