exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-become-a-dealer-jsx": () => import("./../../../src/pages/become-a-dealer.jsx" /* webpackChunkName: "component---src-pages-become-a-dealer-jsx" */),
  "component---src-pages-california-core-jsx": () => import("./../../../src/pages/california-core.jsx" /* webpackChunkName: "component---src-pages-california-core-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-checkout-jsx": () => import("./../../../src/pages/checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-discount-program-jsx": () => import("./../../../src/pages/discount-program.jsx" /* webpackChunkName: "component---src-pages-discount-program-jsx" */),
  "component---src-pages-finance-jsx": () => import("./../../../src/pages/finance.jsx" /* webpackChunkName: "component---src-pages-finance-jsx" */),
  "component---src-pages-incentives-jsx": () => import("./../../../src/pages/incentives.jsx" /* webpackChunkName: "component---src-pages-incentives-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-jsx": () => import("./../../../src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-referral-jsx": () => import("./../../../src/pages/referral.jsx" /* webpackChunkName: "component---src-pages-referral-jsx" */),
  "component---src-pages-request-a-demo-jsx": () => import("./../../../src/pages/request-a-demo.jsx" /* webpackChunkName: "component---src-pages-request-a-demo-jsx" */),
  "component---src-pages-solecsave-jsx": () => import("./../../../src/pages/solecsave.jsx" /* webpackChunkName: "component---src-pages-solecsave-jsx" */),
  "component---src-pages-solectrac-find-dealer-jsx": () => import("./../../../src/pages/solectrac-find-dealer.jsx" /* webpackChunkName: "component---src-pages-solectrac-find-dealer-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-upcoming-events-jsx": () => import("./../../../src/pages/upcoming-events.jsx" /* webpackChunkName: "component---src-pages-upcoming-events-jsx" */),
  "component---src-pages-warranty-jsx": () => import("./../../../src/pages/warranty.jsx" /* webpackChunkName: "component---src-pages-warranty-jsx" */),
  "component---src-templates-news-category-jsx": () => import("./../../../src/templates/news-category.jsx" /* webpackChunkName: "component---src-templates-news-category-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

